<template  >
    <div class="vh-center   text-center h-100 d-flex align-items-center justify-content-center">
        <b-card>

            <b-list-group>
                <b-list-group-item>
                    <qr-code :text="qr_code"></qr-code>
                </b-list-group-item>

                <b-list-group-item>
                    ສູນບໍລິການ: {{ fld.unit_name }}
                </b-list-group-item>

                <b-list-group-item>

                    <p class="mb-1 ">
                        ຊື່ ແລະ ນາມສະກຸນ
                    </p>
                    <div class="text-success">
                        <h5 class="mb-1"> {{ fld.fname }} {{ fld.lname }} </h5>

                    </div>
                </b-list-group-item>
                <b-list-group-item>
                    <p class="mb-1">
                        ວັນ ເດືອນ ປີເກີດ
                    </p>
                    <div class="text-success">
                        <h5 class="mb-1"> {{ fld.dob }} ອາຍຸ {{ fld.age }} ປີ </h5>

                    </div>
                </b-list-group-item>

                <b-list-group-item>
                    <p class="mb-1">
                        ວັນທີ່, ເວລານັດ
                    </p>
                    <div class="text-danger">

                        <h5 class="mb-1"> {{ fld.queue_date | dateString }} </h5>

                    </div>

                </b-list-group-item>
                <b-list-group-item>
                    <p class="mb-1">
                        ໝາຍເຫດ:
                    </p>
                    <div>ຕ້ອງປະກອບເອກະສານໃຫ້ຄົບຖ້ວນຕາມຄຳແນະນຳ</div>
                </b-list-group-item>
                <b-list-group-item>
                    <b-button variant="primary" @click="onPrint"> <b-icon icon="printer"></b-icon> ສັ່ງພີມ</b-button>
                </b-list-group-item>

            </b-list-group>

        </b-card>

    </div>
</template>
<script>
//import axios from "axios";
export default {
    methods: {
        onPrint() {
            window.print()
        }
    },
    data() {
        return {
            fld: [],
            qr_code: "",
            dates: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
        }
    },
    mounted() {
        // this.fld= JSON.parse(this.$route.query.c)
        // axios
        //     .get(
        //         this.$store.getters.getBaseUrl + "/v_queue_details/queue_id/" + this.$route.params.value, {
        //         headers: {
        //             "Content-Type": "application/json",
        //             Authorization: 'Bearer anUrbLQdEVK7V/w3Mjkp28ZDJXgDH7SpKeqtKBjkqMnNJT4OQ?ukI8w7M5/HmxsONU78KEg4p=!5r0WOf=5/zBa9h/PTI42lb5JEhR855=LTAt!!AeJBFkmFtrpOwS7KhMuk!=B=8Rn=aqz6U59ZoPATYEKFkaZ-C/4ZRUXjUfb4DA0-69KsueEHkI/RW8mD2lLudpbe5JZpti=uQmQe5Nq2G6pP9//DX9hGm3yFpVMInuh-O50nbYdMej1miJTs',
        //             "Accept-Encoding": "identity"
        //         }
        //     }
        //     )
        //     .then((response) => {
        //         // JSON responses are automatically parsed.
        //         this.fld = response.data[0]
        //         //  let  qr= [this.fld.queue_id, this.fld.queue_no, this.fld.fname, this.fld.lname, this.fld.age, this.fld.sex, this.fld.dob, this.fld.queue_date,this.fld.start_time,this.fld.end_time, this.fld.ca1, this.fld.ca2, this.fld.ca3, this.fld.ca4, this.fld.ca5, this.fld.ca6, this.fld.ca7, this.fld.ca8, this.fld.ca9, this.fld.ca10, this.fld.ca11, this.fld.ca12, this.fld.ca13, this.fld.ca14, this.fld.ca15]
        //         let qr = [this.fld.queue_id, this.fld.queue_no, this.fld.fname, this.fld.lname, this.fld.age, this.fld.sex, this.fld.dob, this.fld.queue_date, this.fld.start_time, this.fld.end_time, this.fld.ca1]

        //         this.qr_code = qr.join("|")
        //     })

      
                this.fld= JSON.parse(localStorage.getItem('qr_code'))
               // console.log( this.fld)
               if(this.fld.queue_id!=this.$route.params.value){
                this.qr_code=""
                this.fld=[]
               }else{
                let qr = [this.fld.queue_id, this.fld.queue_no, this.fld.fname, this.fld.lname, this.fld.age, this.fld.sex, this.fld.dob, this.fld.queue_date, this.fld.start_time, this.fld.end_time, this.fld.ca1]

                this.qr_code = qr.join("|")
               }
                


    },
    filters: {
        dateString(dd) {
            let op = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
            const d = new Date(dd);

            return d.toLocaleString('lao', op);
        }
    }


}
</script>
<style  scoped ></style>